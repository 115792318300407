import React from "react";


const GrowthWindowComponent=()=>{
    return(
        <section style={{marginTop:"-1px"}}>
        <div className='text-center' style={{ background: "#24303F",  paddingTop: "27px", paddingBottom: "27px" }}>
          <h4 className='dash-headers'>Don’t Miss Out Growth, ARKART is<br /> The Window TO Your Ecommerce Store</h4>
        </div>
      </section>
    )
}
export {GrowthWindowComponent}