import React from 'react';
import './App.css';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header,Banner,MainBodyTemplate,Footer, PayForWhatYouNeed } from './Components';
import { About } from './About';
import ContactUs from './Components/ContactUs';
import Features from './Components/Features';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/"  element={<MainBodyTemplate/>} />
          <Route path="/about"  element={<About/>} />
          <Route path="/contact"  element={<ContactUs/>} />
          <Route path="/pricing"  element={<PayForWhatYouNeed/>} />
          <Route path="/features"  element={<Features/>} />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
