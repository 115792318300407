import React from 'react';
import "./index.scss"
function About() {
  return (
    <section className='container' style={{marginTop:"104px"}}>
      <h4 class="dash-ser t-1">About ARKART</h4>
      <div className="about">
        <p>Welcome to ARKART , where we engineer unparalleled e-commerce experiences using the latest technologies and industry best practices. Our mission is to transform your business idea into a compelling growth story, leveraging cutting-edge technology to give you a competitive edge in the dynamic world of online commerce. At ARKART, we are committed to redefining the e-commerce landscape, ensuring that your business not only meets but exceeds customer expectations.</p>

          <p>Our platform is designed to provide a seamless and efficient e-commerce experience. We understand that inventory management is crucial for any online store, and our smart inventory sorting feature ensures that your products are always organized and easily accessible. This, coupled with our intelligent product search and filtering options, allows users to quickly find what they are looking for, leading to faster purchases and increased revenue. With ARKART, you can be confident that your store operates smoothly, allowing you to focus on growing your business.</p>

          <p>Delighting your customers is at the heart of what we do. Our platform offers robust marketing tools such as customizable coupons and targeted promotions, helping you engage both new and existing customers effectively. By tailoring marketing strategies to customer preferences, ARKART helps you build strong, lasting relationships with your shoppers, encouraging repeat business and fostering customer loyalty.</p>
      </div>
    </section>
  );
}

export { About };
