import React from 'react';

const ShopidyFeatures = () => {
    let data = [
        {
            image: require("../Assets/Rectangle 1.webp"),
            title: "How Is Retention A Game Changer In Ecommerce",
            class: "cl-4",
            p: "Offer discounts to your existing users through promo codes and encourage repeat purchases. You can also limit the number of times the coupon can be applied on ARKART"
        },
        {
            image: require("../Assets/Rectangle 2.webp"),
            title: "How To Target A Broader Audience",
            class: "cl-4",
            p: "With ARKART variant feature you can target a wider audience and enhance the rate of conversions. Users are more likely to find products that matches their specific needs Keeps the users engaged and reduces the likelihood of customers leaving the site to check for alternative"
        },
        {
            image: require("../Assets/Rectangle 3.webp"),
            title: "Customer Experience Is Everything! Agree Or Not",
            p: "Enhances the overall shopping experience with ARKART. Lets them discover related products they might have missed out during shopping. This feature would enhance the customer satisfaction and convenience"
        }
    ]
    return (
        <div>
            <section className="game-changer-message">
                <div className='container'>
                    <div className='d-none d-md-block ' style={{ marginBottom: "33px" }}>
                        <h2 className='dash-head3 text-center'>Read ARKART Featured Insights, Articles, & Features</h2>
                        <p className='font-p-features c-1' style={{ color: "#FFF" }}>We go beyond science and turn the impossible to possible. Read our latest stories to follow what we do to build a better working world</p>
                    </div>
                    <div className='row mx-auto'>
                        {
                            data.map((res, i) => {
                                return (
                                    <div className='col-md-4 mb-4 mr-4 anim-1' style={{ paddingLeft: "0px" }}>
                                        <div className=''>
                                            <div className=''>
                                                <img src={res.image} alt={res.title} style={{ width: "100%" }} />
                                            </div>
                                            <div>
                                                <h4 className='dash-head1'>{res.title}</h4>
                                            </div>
                                            <div>
                                                <p className='card-para'>{res.p}</p>
                                            </div>
                                            <div>
                                                <button className='btn-black' style={{ border: "1px solid #FFF" }}>
                                                    Read More
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section style={{ background: "#00A295", paddingTop: "48px", paddingBottom: "25px" }}>
                <div className='container'>
                    <div className='text-center' style={{ marginBottom: "50px" }}>
                        <h4 className='dash-board-insights' style={{ color: "#FFF" }}>Insights For Smarter Marketing</h4>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <div style={{ background: "#DEEBE9", paddingTop: "20px", paddingBottom: "20px", paddingRight: "0px", paddingLeft: "5px", borderRadius: "10px",minHeight:"205px" }}>
                                <div className='text-center'>
                                    <h4 className='dash-head1 '>Demand Forecasting</h4>
                                </div>
                                <div className='d-1'>
                                    <p className='font-p'>Analyzing sales data would help in efficient inventory management
                                        and demand forecasting. This would ensure you to maintain adequate
                                        stock levels, avoid overstocking or stockouts, restock popular items
                                        and optimize procurement process.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div style={{ background: "#DEEBE9", paddingTop: "20px", paddingBottom: "20px", paddingRight: "0px", paddingLeft: "5px", borderRadius: "10px",minHeight:"205px" }}>
                                <div className='text-center'>
                                    <h4 className='dash-head1 '>Performance Analysis</h4>
                                </div>
                                <div className='d-1'>
                                    <p className='font-p'>ARKART offers you comprehensive data on revenue,
                                        average order value, and sales trends. You can identify
                                        peak levels of sales periods, impact of promotions and
                                        discounts.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ background: "#24303F", paddingTop: "24px", paddingBottom: "42px" }}>
                <div className='text-center'>
                    <h4 className='dash-board-insights' style={{ color: "#FFF", }}>Not convinced we’re the one?</h4>
                </div>
                <div className='text-center'>
                    <h4 className='dash-board-insights' style={{ color: "#FFF", }}>We would love to take a challenge</h4>
                </div>
                <div className='d-flex justify-content-center gap-3'>
                    <button className='btn-green' style={{ paddingLeft: "35px", paddingRight: "35px", paddingTop: "14px", paddingBottom: "14px", }}>Sign Up</button>
                    <button className='btn-green' style={{ paddingLeft: "35px", paddingRight: "35px", paddingTop: "14px", paddingBottom: "14px", }}>Log In</button>
                </div>
            </section>
        </div>
    );
}

export { ShopidyFeatures };