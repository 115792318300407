import React from "react";
import { MdMail, MdPhoneInTalk, MdPinDrop } from 'react-icons/md';
import ContactForm from "../Components/ContactForm"
const ContactUs = () => {
    return (
        <div>
            <div class="container" id="contact">
                <div class="inner-container">
                    <section class="contact" id="contact">
                        <div class="container">
                            <h2>Our Work Speaks For Us</h2>
                            <p class="contact-quote">Do You Have A Project That You Would Like To Discuss On? Talk To Our Expert</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="email">
                                                <MdMail size={40} color={"#0d53ad"} />
                                                <h3>Email</h3>
                                                <p><a href="mailto:arka@arkatechquest.in" className="anchor-text">arka@arkatechquest.in</a></p>
                                                <br />

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="phone">
                                                <MdPhoneInTalk  size={40} color={"#0d53ad"} />
                                                <h3>Call</h3>
                                                <p><a href={`tel:+91 8019244542`} className="anchor-text">+91 8019244542</a></p>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="address">
                                        <MdPinDrop  size={40} color={"#0d53ad"} />
                                        <h3>Address</h3>
                                        <p>1st floor, Plot No: 20/P, Road NO:2Green Park Avenue, Near Suchitra CircleBehind KLM Shopping Mall Jeedimetla Hyderabad-50067 Telangana</p>
                                        <button type="submit" style={{marginTop:"7px"}} class="btn btn-primary"><a href="https://www.google.com/maps/dir/?api=1&destination=17.5053186,78.4755511&travelmode=driving" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}>Get Directions</a></button>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box-with-shadow">
                                        <h2>Submit Your Query</h2>
                                         <ContactForm /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;