import React, { useState } from 'react';
import './Modal.css'; // Import your CSS file for modal styles
import { NavLink,useLocation } from 'react-router-dom';
import { MdOutlineStarPurple500 } from "react-icons/md";

const NavBarModal = ({ isOpen, onClose,settogole }) => {
  const location = useLocation();
  console.log(location)
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
      <nav className='container' onClick={()=>settogole(!isOpen)}>
            <div className='d-flex flex-column'style={{paddingBottom:"24px"}}>
             <div>
                <NavLink exact to="/" activeClassName="active">Home</NavLink>
              </div>
             <div>
                <NavLink to="/about" activeClassName="active">About</NavLink>
              </div>
            {location.pathname==="/"?<><div>
             <a href="#features" activeClassName="active">Features</a>
              </div>
              <div>
              <a href="#pricing" activeClassName="active">Pricing</a>
              </div></>:<><div>
             <NavLink to="/" activeClassName="active">Features</NavLink>
              </div>
              <div>
              <NavLink to="/" activeClassName="active">Pricing</NavLink>
              </div></>}
             <div>
                <NavLink to="/contact" activeClassName="active">Contact</NavLink>
              </div>
            </div>
            <button className='btn-block btn-feedback'><MdOutlineStarPurple500 size={20}/> Feedback</button>
          </nav>
      </div>
    </div>
  );
};
export {NavBarModal}