import React from 'react';
import { ImFacebook } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { NavLink, useLocation } from 'react-router-dom';
const Footer = () => {
    const location = useLocation();
    console.log(location)
    return (
        <section>
            <div className='font-height'>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ flex: 1 }} viewBox="0 0 1440 320"><path fill="#24303F" fill-opacity="1" d="M0,128L80,122.7C160,117,320,107,480,133.3C640,160,800,224,960,234.7C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
            </div>
            <footer className='footer-main'>
                <div className="container">
                    <div className='row text-white'>
                        <div className='col-lg-3 col-6'>
                            <h4 className='dash-footer'>Useful Links</h4>
                            <ul className='p-0 footer-f'>
                                <li>
                                    <NavLink exact to="/" style={{ color: "#FFF" }}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about" style={{ color: "#FFF" }}>About</NavLink>
                                </li>
                                {location.pathname === "/" ? (
                                    <>
                                        <li>
                                            <a href="#features" style={{ color: "#FFF" }}>Features</a>
                                        </li>
                                        <li>
                                            <a href="#pricing" style={{ color: "#FFF" }}>Pricing</a>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <NavLink to="/" style={{ color: "#FFF" }}>Features</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/" style={{ color: "#FFF" }}>Pricing</NavLink>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <NavLink to="/contact" style={{ color: "#FFF" }}>Contact</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <h4 className='dash-footer'>Follow us</h4>
                            <div className='d-flex  gap-2'>
                                <a href="https://www.facebook.com/profile.php?id=61560084383998" target="_blank" style={{ width: "30px", height: "30px", background: "black", display: "flex", color: '#ffffff', textDecoration: 'none', justifyContent: "center", alignItems: "center", borderRadius: "50%" }}>
                                    <ImFacebook size={20} />
                                </a>
                                <a href="https://www.instagram.com/arkart.atq/?next=%2F" target="_blank" style={{ width: "30px", height: "30px", background: "black", display: "flex", color: '#ffffff', textDecoration: 'none', justifyContent: "center", alignItems: "center", borderRadius: "50%" }}>
                                    <FaInstagram size={20} />
                                </a>
                                <a href="https://www.linkedin.com/in/arkart-atq-37206330a/" target="_blank" style={{ width: "30px", height: "30px", background: "black", display: "flex", color: '#ffffff', textDecoration: 'none', justifyContent: "center", alignItems: "center", borderRadius: "50%" }}>
                                    <FaLinkedin size={20} />
                                </a>
                            </div>
                            <div className='d-block d-md-none mt-4'> {/* Display only on small screens */}
                                <h4 className='font-p text-white'>Terms of Service</h4>
                            </div>
                            <div className=' d-block d-md-none'> {/* Display only on small screens */}
                                <h4 className='font-p text-white'>Privacy Policy</h4>
                            </div>
                        </div>
                        <div className='col-md-3 d-none d-md-block'> {/* Display only on mobile */}
                            <h4 className='font-p text-white'>Terms of Service</h4>
                        </div>
                        <div className='col-md-3 d-none d-md-block'> {/* Display only on mobile */}
                            <h4 className='font-p text-white'>Privacy Policy</h4>
                        </div>
                    </div>



                    <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                        <h4 className='card-para t-1' style={{ color: "#FFF" }}>Copyright&copy;All rights Reserved by ARKART .in</h4>
                    </div>
                </div>
            </footer>
        </section>
    );
};

export { Footer };
