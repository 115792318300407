import React from "react";
import { Banner } from "./Banner";
import { GrowthWindowComponent } from "./GrowthWindowComponent";
import { InventryComponent } from "./InventryComponent";
import { AccessiblePurchaseComponent } from "./AccessiblePurchaseComponent";
import {Platform} from "./PlatForm"
import { GameChangerMessage } from "./GameChangerMessage";
import { EmbracingCloud } from "./EmbracingClound";
import { ShopidyFeatures } from "./ShopdyFeatures";
import {PayForWhatYouNeed} from "./PayForWhatYouNeed"
import ContactUs from "./ContactUs";
const MainBodyTemplate=()=>{
    return(
        <section>
         <Banner/>
         <GrowthWindowComponent/>
         <InventryComponent/>
         <AccessiblePurchaseComponent/>
         <Platform/>
         <GameChangerMessage/>
         <EmbracingCloud/>
         <ShopidyFeatures/>
         <PayForWhatYouNeed/>
        </section>
    )
}
export {MainBodyTemplate}