import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    email: '',
    query: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      
      setFormData({
        name: '',
        contact: '',
        email: '',
        query: ''
      });
    } catch (error) {
      console.error('Error inserting data:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3 mt-3">
        <label htmlFor="name">Name:</label>
        <input type="text" className="form-control" id="name" placeholder="Enter Name" name="name" value={formData.name} onChange={handleChange} />
      </div>
      <div className="mb-3 mt-3">
        <label htmlFor="contact">Contact No:</label>
        <input type="text" className="form-control" id="contact" placeholder="Enter Contact No" name="contact" value={formData.contact} onChange={handleChange} />
      </div>
      <div className="mb-3 mt-3">
        <label htmlFor="email">Email:</label>
        <input type="email" className="form-control" id="email" placeholder="Enter Email" name="email" value={formData.email} onChange={handleChange} />
      </div>
      <div className="mb-3 mt-3">
        <label htmlFor="query">Query:</label>
        <textarea className="form-control" rows="2" id="query" name="query" value={formData.query} onChange={handleChange}></textarea>
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  );
};

export default ContactForm;
