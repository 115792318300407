import React from 'react';
import { Platform } from './PlatForm';
import { GameChangerMessage } from './GameChangerMessage';
import { EmbracingCloud } from './EmbracingClound';
import { ShopidyFeatures } from './ShopdyFeatures';

const Features = () => {
  return (
    <div>
      <Platform />
      <GameChangerMessage />
      <EmbracingCloud />
      <ShopidyFeatures />
    </div>
  );
}

export default Features;
